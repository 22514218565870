import { ApiService } from '@app/core/abstracts/api.service'
import { NavigationUrl } from '@app/core/constants/navigation'
import { Page } from '@app/core/interfaces/page'
import { ResponseInterface } from '@app/core/interfaces/response'
import { StructureItem } from '@app/core/interfaces/structure'
import { PageService } from '@app/core/services/page.service'
import { DevicesService } from '@app/features/devices/devices.service'
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, tap } from 'rxjs'
import { NavigationItem } from 'src/app/common/header/components/navigation/shared/types'

import { MenuType } from '../enums/menu.enum'

import { LangService } from './lang.service'

import { Location } from '@angular/common'
import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends ApiService {
  structure$: BehaviorSubject<StructureItem[]> = new BehaviorSubject<StructureItem[]>([])
  additionalLinks$: BehaviorSubject<NavigationItem[]> = new BehaviorSubject<NavigationItem[]>([])

  private langService = inject(LangService)
  private page$ = this.pageService.commonTranslations$.pipe(
    filter((translations) => !!translations['header']),
    map((translations) => translations['header']),
  )

  navigationItems$: Observable<NavigationItem[]> = combineLatest([this.page$, this.devicesService.devices$]).pipe(
    switchMap(([pageTranslations, devices]) => {
      return this.structure$.asObservable().pipe(
        map((data) => {
          const menuItems = this.groupMenuItems(data, pageTranslations)
          const result: any = menuItems.map((item) => {
            if (item.menuId !== 1) {
              return {
                menuId: item.menuId,
                label: item.label,
                items: item.items
                  ?.filter((sub) => sub?.parent?.data?.item)
                  .map((subItem) => {
                    const subPage = Page.fromJson<Page>(subItem.parent.data.item, this.langService)

                    const prefix = subItem.parent?.data?.item?.typeId === 2 ? 'add/' : ''

                    return {
                      position: subItem.position,
                      label: subPage?.translation?.data?.menuTitle ?? subPage?.translation?.data?.title,
                      url: '/' + prefix + subItem.parent.data.item.slug,
                    }
                  }),
              }
            }

            const page = item?.parent?.data?.item ? Page.fromJson<Page>(item.parent.data.item, this.langService) : null

            return {
              menuId: item.menuId,
              label: page?.translation.data.menuTitle ?? page?.translation.data.title,
              url: '/' + item?.parent?.data?.item?.slug,
              position: item.position,
            }
          })

          return result
        }),
        map((items) => {
          const etc = items.find((item: NavigationItem) => item.menuId === 5)
          const add = items.find((item: NavigationItem) => item.menuId === 4)
          const services = items.find((item: NavigationItem) => item.menuId === 2)
          const devicesPage = items.find((item: NavigationItem) => item.menuId === 3)

          if (devicesPage) {
            devicesPage.items = devices.map((device) => {
              return {
                ...device,
                url: NavigationUrl.Device + '/' + device.slug,
                label: device.translation.data.title,
              }
            })
          }

          const pages = items
            .filter((item: NavigationItem) => item.menuId === 1)
            .sort((a: NavigationItem, b: NavigationItem) => a.position! - b.position!)

          const res = [services, devicesPage, ...pages, add, etc]
            .filter((item) => item)
            .map((item) => {
              if (item.items?.length) {
                return {
                  ...item,
                  items: item.items,
                }
              }

              return item
            })

          return res
        }),
      )
    }),
  )

  constructor(
    private location: Location,
    private router: Router,
    private devicesService: DevicesService,
    private pageService: PageService,
  ) {
    super()
  }

  goBack(defaultUrl = '/'): void {
    const state = <{ navigationId: number }>this.location.getState()

    if (state?.navigationId !== 1) {
      this.location.back()
    } else {
      this.router.navigateByUrl(defaultUrl)
    }
  }

  initStructure(): Observable<ResponseInterface<StructureItem>> {
    return this.getStructure('/front/structure', { take: 1000, skip: 0 }).pipe(
      tap((resp) => {
        this.structure$.next(resp.data)
      }),
    )
  }

  private groupMenuItems(items: StructureItem[], page: any): StructureItem[] {
    const grouped: any = {}
    const nonGrouped: StructureItem[] = []

    items.forEach((item: StructureItem) => {
      if (item.menuId === MenuType.Link) {
        const uniqUrls = new Set<string>()
        const addTranslation = Page.fromJson<Page>(item, this.langService)

        const addLinks = [
          ...this.additionalLinks$.value,
          {
            position: item.position,
            label: addTranslation?.translation?.data?.title ?? 'Additional link',
            url: item.link ?? '/',
            menuId: item.menuId,
          },
        ].filter((addLink) => {
          if (uniqUrls.has(addLink.url)) {
            return false
          } else {
            uniqUrls.add(addLink.url)

            return true
          }
        })

        this.additionalLinks$.next(addLinks)

        return
      }

      if (item.menuId !== 1) {
        if (!grouped[item.menuId]) {
          grouped[item.menuId] = []
        }

        grouped[item.menuId].push(item)
      } else if (item.parentTypeId !== 4) {
        nonGrouped.push(item)
      }
    })

    const groupedArray = Object.keys(grouped).map((key) => ({
      menuId: Number(key),
      items: grouped[key],
      label: this.getLabel(page, Number(key)),
    }))

    return [...groupedArray, ...nonGrouped]
  }

  private getLabel(page: any, menuId: number): string {
    switch (menuId) {
      case 2:
        return page?.translation?.data?.servicesTitle ?? 'Довірчі послуги'
      case 3:
        return page?.translation?.data?.deviceTitle ?? 'Захищені носії'
      case 4:
        return page?.translation?.data?.diiaPageTitle ?? 'Дія ID | Дія. Підпис'
      case 5:
        return page?.translation?.data?.additionalPageTitle ?? 'Ще'
      default:
        return ''
    }
  }
}
