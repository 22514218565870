import { Link } from 'src/app/common/links/shared/types'

import { TranslatableModel } from 'src/app/core/abstracts/translate-model'

export class Device extends TranslatableModel<DeviceTranslationData> {
  id!: string
  slug!: string
  isPdv!: boolean
  isZnok!: boolean
  price!: number
  image?: string
}

export interface DeviceDetails {
  about?: string
  pluses?: string
  os?: string
  interfaces?: string
  security?: string
  commonDesc?: string
}

export interface DeviceTranslationData {
  title: string
  description: string
  note: string
  details: string
  links: Link[]
}
