import { BehaviorSubject, Observable } from 'rxjs'

import { Device } from './shared/types'

import { Injectable, inject } from '@angular/core'

import { ApiService, BaseHttpParams } from 'src/app/core/abstracts/api.service'
import { ResponseInterface } from 'src/app/core/interfaces/response'
import { LangService } from 'src/app/core/services/lang.service'

@Injectable({
  providedIn: 'root',
})
export class DevicesService extends ApiService {
  private langService = inject(LangService)

  devices$: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([])

  getDevices(params: BaseHttpParams): Observable<ResponseInterface<Device>> {
    return this.getList<Device>('/front/product', params, (itemData: Device) => Device.fromJson<Device>(itemData, this.langService))
  }

  getDeviceBySlug(id: string): Observable<Device> {
    return this.getById<Device>('/front/product', id, (itemData: Device) => Device.fromJson<Device>(itemData, this.langService))
  }

  initDevices(): void {
    this.getDevices({ take: 10, skip: 0 }).subscribe((data) => {
      this.devices$.next(data.data)
    })
  }
}
